<template>
  <vue-final-modal class="modal-promotions-card-detail">
    <div class="vfm__head">
      <div class="vfm__control vfm__control-left" />

      <div class="vfm__title">
        {{ titleLabel }}
      </div>

      <div class="vfm__control vfm__control-right">
        <atomic-icon id="close" class="vfm__close" @click="closeModal('promotions-card-detail')" />
      </div>
    </div>

    <div class="vfm__body">
      <card-promotion :card-info="cardInfo" modalStyle></card-promotion>
      <div
        class="marked-content"
        v-html="DOMPurify.sanitize(marked.parse(cardInfo.info || '') as string, { FORBID_TAGS: ['style'] })"
      />
    </div>
    <div class="vfm__footer">
      <button-base class="modal-promotions-card-detail__button" type="primary" size="md" @click="clickAction()">
        {{ buttonLabel }}
      </button-base>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';
  import type { CIPromotionsGroupCard } from '~/types';
  import DOMPurify from 'isomorphic-dompurify';
  import { marked } from 'marked';

  const props = defineProps<{
    cardInfo: CIPromotionsGroupCard;
    titleLabel: string;
    buttonLabel: string;
    buttonAction: () => Promise<void>;
  }>();

  const { closeModal } = useModalStore();

  const clickAction = async (): Promise<void> => {
    await props.buttonAction();
    await closeModal('promotions-card-detail');
  };
</script>

<style src="~/assets/styles/components/modal/promotions-card-detail.scss" lang="scss" />
